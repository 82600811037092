// import { getSentryHub } from '../sentry';

const loggerWrapper = (): any => {
  let queue: any = [];
  let ECHO_TOKEN = {};
  let RESET_INPUT = '%c ';
  let RESET_CSS = '';

  const setDate = () => {
    const date = new Date();
    const value = `${date.toLocaleString()} GMT ${
      date.getTimezoneOffset() / -60
    }`;
    queue.push({
      value,
      css: 'display: inline-block ; color: #242424 ; font-weight: bold ; padding: 3px 7px 3px 7px ;',
    });

    return ECHO_TOKEN;
  };

  const setLevel = (value: string) => {
    queue.push({
      value: value.toUpperCase(),
      css: 'display: inline-block ; color: #242424 ; font-weight: bold ; padding: 3px 7px 3px 7px ;',
    });

    return ECHO_TOKEN;
  };

  const setCategory = (value: string, color: string) => {
    queue.push({
      value: value,
      css: `display: inline-block ; background-color: ${color} ; color: #ffffff ; font-weight: bold ; padding: 3px 7px 3px 7px ; border-radius: 3px 3px 3px 3px ;`,
    });

    return ECHO_TOKEN;
  };

  const setTitle = (value: string, color: string) => {
    queue.push({
      value: value,
      css: `display: inline-block ; color: ${color} ; font-weight: bold ; padding: 3px 7px 3px 7px ;`,
    });

    return ECHO_TOKEN;
  };

  const setDesc = (object: any): any => {
    const value = JSON.stringify(object);

    if (object !== '') {
      queue.push({
        value,
        css: 'display: inline-block ; color: #242424 ; font-weight: bold ; padding: 3px 7px 3px 7px ;',
      });

      return ECHO_TOKEN;
    } else {
      return '';
    }
  };

  const using = (consoleFunction: any) => {
    function consoleFunctionProxy() {
      let inputs = [];
      let modifiers = [];

      for (let i = 0; i < arguments.length; i++) {
        // Когда вызываются служебные методы форматирования, они возвращают
        // специальный токен. Это означает, что мы должны вытащить
        // соответствующее значение из ОЧЕРЕДИ вместо попытки
        // вывод данного аргумента напрямую.
        if (arguments[i] === ECHO_TOKEN) {
          let item = queue.shift();

          inputs.push(`%c${item.value}`, RESET_INPUT);
          modifiers.push(item.css, RESET_CSS);
          // Для каждого другого типа аргумента вывести значение напрямую.
        } else {
          let arg = arguments[i];

          if (typeof arg === 'object' || typeof arg === 'function') {
            inputs.push('%o', RESET_INPUT);
            modifiers.push(arg, RESET_CSS);
          } else {
            inputs.push(`%c${arg}`, RESET_INPUT);
            modifiers.push(RESET_CSS, RESET_CSS);
          }
        }
      }

      consoleFunction(inputs.join(''), ...modifiers);

      queue = [];
    }

    return consoleFunctionProxy;
  };

  return {
    log: using(console.log),
    info: using(console.info),
    warn: using(console.warn),
    error: using(console.error),
    trace: using(console.trace),
    group: using(console.group),
    groupEnd: using(console.groupEnd),
    setDate: setDate,
    setLevel: setLevel,
    setCategory: setCategory,
    setTitle: setTitle,
    setDesc: setDesc,
  };
};

export default loggerWrapper;
