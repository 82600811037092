export const LOG_LEVEL = 'log';
export const DEBUG_LEVEL = 'debug';
export const ERROR_LEVEL = 'error';
export const WARN_LEVEL = 'warning';
export const INFO_LEVEL = 'info';

export const MOBX_COLOR = '#298c5b';
export const MOBX_TITLE = 'mobx';
export const MOBX_DESC = 'Change mobx state:';

export const GAME_COLOR = '#0092fa';
export const GAME_TITLE = 'game';

export const WEBRTC_PLAYER_COLOR = '#ca0082';
export const WEBRTC_PLAYER_TITLE = 'webRTC player';

export const WBS_COLOR = '#9e5d43';
export const WBS_TITLE = 'wbs';

export const API_COLOR = '#37d3cc';
export const API_TITLE = 'api';
